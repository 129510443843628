<script setup>
import {useRouter} from 'vue-router'
import ClickAgainToConfirmButton from '../ClickAgainToConfirmButton.vue'
import KebabMenu from '../KebabMenu.vue'
import {getItemClass, edit as _edit, getEditRouteOptions, archive as _archive, propDef} from './Shared'
const router = useRouter()
const props = defineProps(propDef)
const emit = defineEmits(['archive'])
const itemClass = getItemClass(props, props.data)
const edit = () => _edit(props, router, props.data)
const editRouteOptions = getEditRouteOptions(props, props.data)
const archive = () => _archive(props, emit, props.data)
</script>
<template>
  <div :class="itemClass" class="ListItem0">
    <div class="V3ResourceList__Name" @click="edit"><span v-shtml="data.name" /></div>
    <KebabMenu>
      <router-link :to="editRouteOptions">Edit</router-link>
      <ClickAgainToConfirmButton idleText="Archive" @action="archive" />
    </KebabMenu>
  </div>
</template>
