import GETCache from '@/classes/GETCache'
import {mapArray} from '../../helpers/format'
import {Role, Seller, StaffGroupRoles} from './types'

export const configurableRoles = ['staff-member', 'read-only']

export const roles = {
  'portal-admin': null,
  'seller-admin': null,
  'staff-member': ['configure-events', 'manage-staff', 'configure-checkout-rules', 'manage-cash', 'salespoint-selection', 'admissions', 'admissions-unredeem', 'admissions-force', 'admissions-super', 'payment-vouchers', 'manage-customers', 'manage-memberships', 'manage-orgs', 'sales', 'apply-code', 'modify-past', 'payment-cash', 'payment-external', 'sales-refund', 'sales-hold', 'sales-super', 'manage-savedmethod', 'payment-savedmethod'],
  'read-only': ['read-only-events', 'read-only-orders', 'read-only-all', 'reporting-author', 'reporting'],
  developer: null
}

export const fields = [
  {
    name: 'portal-admin',
    label: 'Global Ticketure Administrator - (portal-admin)',
    caption: 'The most powerful administrator role, granting all permissions possible for all sites'
  },
  {
    name: 'seller-admin',
    label: 'Site Ticketure Administrator - (seller-admin)',
    caption: 'Administrator with full access to specific sellers within Ticketure'
  },
  {
    name: 'staff-member',
    label: 'Staff-member',
    caption: 'Customizable role for general staff',
    subFields: [
      {
        name: 'administration',
        label: 'Administration',
        caption: 'Enable the ability to configure events, checkout rules and cashboxes',
        subFields: [
          {
            name: 'configure-events',
            label: 'Configure Events - (configure-events)',
            caption: 'Enables the events menu item and the ability to modify items managed there.'
          },
          {
            name: 'configure-checkout-rules',
            label: 'Configure Checkout Rules - (configure-checkout-rules)',
            caption: 'Enables the checkout rules menu item and the ability to modify the items managed there.'
          },
          {
            name: 'manage-cash',
            label: 'Manage Cash - (manage-cash)',
            caption: 'Allows managing cashboxes and cashbox permissions for staff.'
          },
          {
            name: 'salespoint-selection',
            label: 'Assign POS Salespoints (salespoint-selection)',
            caption: 'Allows the assigning and re-assigning of Salespoints to POS devices'
          },
          {
            name: 'manage-staff',
            allSellersOnly: true,
            label: 'Manage staff permissions - (manage-staff)',
            caption: 'Enables the settings menu item and the ability to manage staff and staff groups'
          }
        ]
      },
      {
        name: 'admissions',
        label: 'Admissions - (admissions)',
        caption: 'Enables ticket scanning and attendee list functions within the POS',
        subFields: [
          {
            name: 'admissions-unredeem',
            label: 'Unredeem - (admissions-unredeem)',
            caption: 'Enables the ability to undo an admission, leaving the ticket to be redeemed later'
          },
          {
            name: 'admissions-force',
            label: 'Admit Tickets Early or Late - (admissions-force)',
            caption: 'Enables the ability to admit tickets outside their admission window (turned up early/late/on the wrong day, etc)'
          },
          {
            name: 'admissions-super',
            label: 'Re-admit Used Tickets - (admissions-super)',
            caption: 'Grants the two prior privileges and also the ability to scan tickets beyond their admit limit'
          },
          {
            name: 'payment-vouchers',
            label: 'Admit Vouchers - (payment-vouchers)',
            caption: 'Enables the staff member to conduct voucher swaps'
          }
        ]
      },
      {
        name: 'manage-customers',
        label: 'Manage Customers - (manage-customers)',
        caption: 'Enables the ability to modify customer records',
        subFields: [
          {
            name: 'manage-memberships',
            label: 'Manage Memberships - (manage-memberships)',
            caption: 'Enables the ability to edit membership validity and auto-renew status'
          },
          {
            name: 'manage-savedmethod',
            label: 'Manage Saved Payment Methods - (manage-savedmethod)',
            caption: 'Enables the ability to add, replace or delete saved cards'
          },
          {
            name: 'payment-savedmethod',
            label: 'Use Saved Payment Methods - (payment-savedmethod)',
            caption: 'Enables the ability to use saved cards at checkout'
          },
          {
            name: 'manage-orgs',
            label: 'Create Organizations - (manage-orgs)',
            caption: 'Enables the ability to create/modify/edit organizations'
          }
        ]
      },
      {
        name: 'sales',
        label: 'Sales - (sales)',
        caption: 'Enables sales in the CMS and POS',
        subFields: [
          {
            name: 'apply-code',
            label: 'Apply Discounts - (apply-code)',
            caption: 'Enables the ability to retrieve listed discounts and add and remove discount codes'
          },
          {
            name: 'modify-past',
            label: 'Edit Past Dated Orders - (modify-past)',
            caption: 'Enables the ability to modify orders for visit dates that are earlier than the configured limit for unprivileged staff'
          },
          {
            name: 'payment-cash',
            label: 'Cash Payments - (payment-cash)',
            caption: 'Enables the ability to take cash payments'
          },
          {
            name: 'payment-external',
            label: 'External Payments - (payment-external)',
            caption: 'Enables the ability to use external payment methods'
          },
          {
            name: 'sales-refund',
            label: 'Can Refund - (sales-refund)',
            caption: 'Enables the ability to refund tickets and charges'
          },
          {
            name: 'sales-hold',
            label: 'Hold Carts - (sales-hold)',
            caption: 'Enables the ability to hold carts'
          },
          {
            name: 'sales-super',
            label: 'Issue Tickets Before Payment - (sales-super)',
            caption: 'Enables the ability to issue tickets without payment and the ability to invalidate and revalidate tickets.'
          }
        ]
      }
    ]
  },
  {
    name: 'read-only',
    label: 'Read-only / Reporting',
    caption: 'Customizable search and analysis role',
    subFields: [
      {
        name: 'read-only-orders',
        label: 'View Customers and Orders - (read-only-orders)',
        caption: 'Enables the customers and orders menu items in read-only mode',
        subFields: [
          {
            name: 'read-only-all',
            label: 'View Events and Checkout Rules - (read-only-all)',
            caption: 'Enables the remaining menu items in read-only mode'
          }
        ]
      },
      {
        name: 'read-only-events',
        label: 'View Events - (read-only-events)',
        caption: 'Enables the Events tab in read-only mode'
      },
      {
        name: 'reporting',
        label: 'Reporting - (reporting)',
        caption: 'Enables the Reports tab on the Seller menu',
        subFields: [
          {
            name: 'reporting-author',
            label: 'Author Kibana Reports - (reporting-author)',
            caption: 'Enables the creation and editing of reports in Kibana'
          }
        ]
      }
    ]
  },
  {
    name: 'developer',
    label: 'Developer',
    caption: 'Access to API documentation'
  }
]

export const fieldsByName = mapArray(fields, 'name')
const staffMemberAdminRoles = fieldsByName
  .get('staff-member')
  .subFields.find(field => field.name === 'administration')
  .subFields.map(field => field.name)
export const staffMemberAdministrationRoles: Set<string> = new Set(staffMemberAdminRoles)

export function fetchStaffGroups(staffGroups, id?: string): Promise<void> {
  const url = id ? `staff_group/${id}` : 'staff_group'
  const getGroups = window.APIService.get(url, {_embed: 'staff_group_role,role'})
  const getSellers = GETCache.get('seller')

  let groupRoles: Array<StaffGroupRoles> = null
  let sellers: Map<string, Seller> = null
  let rolesById: Map<string, Role> = null

  return Promise.all([getGroups, getSellers]).then(([groups, sellersData]) => {
    staffGroups.value = groups.staff_group._data
    groupRoles = groups.staff_group_role._data
    rolesById = mapArray(groups.role._data)
    sellers = sellersData

    // If a staff group is configured for all sellers the seller_id will be empty
    // Set the seller_id to 'all' in these cases
    groupRoles.forEach(role => {
      if (!role.seller_id) role.seller_id = 'all'
    })

    const rolesByGroup = mapArray(groupRoles, 'staff_group_id', false, true)

    // Add seller/roles combinations to each staff group
    staffGroups.value.forEach(group => {
      group.sellerRoles = []
      const groupRoles = rolesByGroup.get(group.id)
      const rolesBySeller = groupRoles ? mapArray(groupRoles, 'seller_id', false, true) : new Map()

      // Process roles from a flat array into a hierarchical structure used in the UI
      Array.from(rolesBySeller).forEach(([sellerID, sellerRoles]) => {
        const groupedRoles = {}

        sellerRoles.forEach(role => {
          const name: string = rolesById.get(role.role_id).name
          if (roles.hasOwnProperty(name)) {
            // If this is a parent role, it doesn't have any sub-roles
            groupedRoles[name] = null
          } else {
            // Otherwise this must be a sub-role
            // Find its parent role
            const parentRole = Object.keys(roles).find(r => roles[r]?.includes(name))
            if (!groupedRoles[parentRole]) groupedRoles[parentRole] = []

            // Set this sub-role as a child of the parent role
            groupedRoles[parentRole].push(name)
          }
        })

        Object.entries(groupedRoles).forEach(([parentRole, subRoles]) => {
          group.sellerRoles.push({
            seller: sellerID === 'all' ? {name: 'All Sellers', id: 'all'} : sellers.get(sellerID),
            roles: {parentRole, subRoles}
          })
        })
      })
    })
  })
}
