<script>
import {computed, ref, toRefs, watch, reactive} from 'vue'
import moment from 'moment'
import makeModalCmp from './Abstract'
import daterange from '@/components/DateRange.vue'
import weekdays from '@/components/WeekdaySelector.vue'
import timerange from '@/components/TimeRange.vue'
import DDL from '@/components/DDL.vue'
import {isSame} from '@/helpers/comparison'
import {dClone, arrayRemove, strip, replace, randomString, dateFormat} from '@/helpers/format'
import ScheduleRuleCondition from './ScheduleRuleCondition.vue'
import {setError, cleanErrors} from '@/helpers/ErrorHolder'

const maxDateRanges = 25

const thenOptions = [
  {
    code: 'changeTo',
    label: 'Change price tier to'
  },
  {
    code: 'moveUp',
    label: 'Move up'
  },
  {
    code: 'moveDown',
    label: 'Move down'
  }
]

const tierMoveOptions = [
  {
    code: 1,
    label: '1 tier'
  },
  {
    code: 2,
    label: '2 tiers'
  },
  {
    code: 3,
    label: '3 tiers'
  }
]

const defConditions = {
  Start: {fn_bool: 'LT', args: [{ref: 'Start'}, {from_now: 'P1D'}]},
  TicketsAvail: {fn_bool: 'LT', args: [{ref: 'TicketsAvailPercent'}, {int: 10}]},
  CapacityUsed: {fn_bool: 'LT', args: [{ref: 'CapacityUsedPercent'}, {int: 10}]}
}

const condClone = obj => {
  obj = dClone(obj)
  obj._uid = randomString('uid') // needed for v-for key
  return obj
}

const attachTicketsAvailWatcher = cond =>
  watch(toRefs(cond.args[0]).ref, (v, ov) => {
    if ((v.includes('Tickets') && ov.includes('Tickets')) || (v.includes('Capacity') && ov.includes('Capacity'))) {
      // When switching between tickets and percentage (but not between used/available percent or sold/remaining tickets),
      // change the int value to some sensible default.
      // Otherwise we'd need to deal with percentage > 99.
      cond.args[1].int = 50
    }
  })

const cmp = makeModalCmp(setup => {
  if (!setup.data.schedule.day_of_week) {
    setup.data.schedule.day_of_week = []
  }
  setup.isEdit = !setup.data.isAdd
  const picker = setup.data.schedule.tier_pickers[0]
  if (picker.condition) {
    picker.condition.args.forEach(attachTicketsAvailWatcher)
  }
  const thenValue = ref(picker.adjustor === 'absolute' ? 'changeTo' : picker.offset > 0 ? 'moveUp' : 'moveDown')
  const tierListOptions = []
  setup.data.tiers.forEach((label, code) => tierListOptions.push({code, label}))
  const tierValue = ref(Math.abs(picker.offset))
  const tierOptions = ref(picker.adjustor === 'absolute' ? tierListOptions : tierMoveOptions)
  watch(thenValue, v => {
    const tierOptionsShouldBe = v === 'changeTo' ? tierListOptions : tierMoveOptions
    picker.adjustor = v === 'changeTo' ? 'absolute' : 'relative'
    if ((v === 'moveUp' && picker.offset < 0) || (v === 'moveDown' && picker.offset > 0)) {
      picker.offset = -picker.offset
    }
    if (!isSame(tierOptionsShouldBe, tierOptions.value)) {
      tierOptions.value = tierOptionsShouldBe
      tierValue.value = tierOptionsShouldBe[0].code
    }
  })
  watch(tierValue, v => {
    picker.offset = (thenValue.value === 'moveDown' ? -1 : 1) * v
  })
  const datePickerOptions = {
    container: '#AddScheduleRule'
  }
  const onBeforeSubmit = () => {
    strip(setup.data.schedule, cleanErrors, true)
    // Verify that the start dates are before the end dates
    let valid = true
    setup.data.schedule.date_ranges.forEach(dr => {
      if (moment(dr.start).isAfter(moment(dr.end))) {
        setError(dr, 'start', 'The start date is after the end date')
        valid = false
      }
    })
    if (!Boolean(setup.data.schedule.name)) {
      setError(setup.data.schedule, 'name')
      valid = false
    }
    return valid
  }
  const onAND = () => {
    if (!picker.condition) {
      picker.condition = {
        fn_bool: 'AND',
        args: [condClone(defConditions.Start)]
      }
    } else {
      picker.condition.args.push(condClone(defConditions[picker.condition.args[0].args[0].ref === 'Start' ? 'TicketsAvail' : 'Start']))
    }
    attachTicketsAvailWatcher(picker.condition.args[picker.condition.args.length - 1])
  }
  const conds = computed(() => (picker.condition ? picker.condition.args : []))
  const deleteCond = i => {
    const a = picker.condition.args
    arrayRemove(a, a[i])
    if (a.length === 0) {
      picker.condition = null
    }
  }
  // Type can only be changed when there is only 1 condition,
  // that's why we always update the [0] one:
  const changeType = nt => defConditions[nt].args.forEach((a, i) => replace(a, picker.condition.args[0].args[i]))
  const sch = setup.data.schedule
  if (!sch.date_ranges) {
    // These will be missing for new schedules, and also for the single default one,
    // so seed one range:
    sch.date_ranges = [{}]
  }
  const drs = sch.date_ranges
  const addDateRange = () => {
    const lastEnd = moment(drs[drs.length - 1].end)
    drs.push({
      start: lastEnd.add(1, 'day').format(dateFormat),
      end: lastEnd.add(1, 'day').format(dateFormat)
    })
  }
  const deleteDateRange = i => arrayRemove(drs, drs[i])
  sch.date_ranges.forEach(dr => {
    if (!dr._uid) {
      dr._uid = randomString('uid') // needed for v-for key
    }
  })
  return {
    datePickerOptions,
    thenValue,
    thenOptions,
    tierOptions,
    tierValue,
    onBeforeSubmit,
    onAND,
    name: 'Schedule Rule',
    days: [],
    conds,
    deleteCond,
    changeType,
    addDateRange,
    deleteDateRange,
    maxDateRanges,
    ...setup
  }
})
const cmps = {
  daterange,
  weekdays,
  timerange,
  DDL,
  ScheduleRuleCondition
}
Object.keys(cmps).forEach(k => {
  cmp.components[k] = cmps[k]
})
export default cmp
</script>
<template>
  <Modal v-model="show" submitText="Confirm" :onBeforeSubmit="onBeforeSubmit" @submitted="onSubmitted" class="PriceLibModal AddScheduleRule" id="AddScheduleRule" :trapFocus="isEdit">
    <template #header>{{ header }}</template>
    <div class="FormBlock">
      <label class="required">Schedule name</label>
      <field :holder="data.schedule" name="name" />
    </div>
    <div class="FormBlock" :class="{SingleDateRange: data.schedule.date_ranges.length === 1}">
      <label>For sessions between these dates</label>
      <div class="AddScheduleRule__DateRange" v-for="(dr, i) in data.schedule.date_ranges" :key="i">
        <daterange :data="dr" startKey="start" endKey="end" :options="datePickerOptions" />
        <div class="MR__Delete" @click="deleteDateRange(i)" />
      </div>
      <div class="MR__AndButton" @click="addDateRange" v-if="data.schedule.date_ranges.length < maxDateRanges" />
      <label>provided the day is one of</label>
      <weekdays :data="data.schedule.day_of_week" />
      <label>and the time is between</label>
      <timerange :data="data.schedule" startKey="time_of_day_start" endKey="time_of_day_end" :useAllDay="true" />
    </div>
    <ScheduleRuleCondition v-for="(c, i) in conds" :data="c" @delete="deleteCond(i)" @typechange="changeType" :theOnlyOne="conds.length === 1" :key="c._uid" />
    <div class="MR__AndButton" @click="onAND" v-if="conds.length < 2"></div>
    <div class="FormBlock" :class="{MT: conds.length === 2}">
      <label>Then</label>
      <div class="thenDDLs">
        <DDL v-model="thenValue" :options="thenOptions" />
        <DDL v-model="tierValue" :options="tierOptions" />
      </div>
    </div>
  </Modal>
</template>
<style lang="scss">
.AddScheduleRule {
  &__DateRange {
    display: flex;
    justify-content: space-between;
    &:not(:first-of-type) {
      margin-top: 16px;
    }
    & + .MR__AndButton {
      &::before {
        left: 29px;
      }
      background-position: left center;
    }
  }
  .AddScheduleRule__DateRange + label,
  .WeekdaySelector2 + label {
    margin-top: 16px;
  }
  .SingleDateRange .MR__Delete {
    visibility: hidden;
  }
  .MR__AndButton {
    width: 100%;
  }
  .MR__Delete {
    align-self: flex-start;
    margin-top: 10px;
    height: 16px;
  }
  .FormBlock {
    &.MT {
      margin-top: 16px;
    }
    &:nth-child(2) {
      margin-top: 16px;
    }
    background-color: white;
    padding: 16px;
    input {
      width: 100%;
    }
  }
  .thenDDLs {
    display: flex;
    justify-content: space-between;
    > div {
      width: calc(50% - 8px);
    }
  }
}
</style>
