export default {
  $id: '/tix/apiapp',
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
      pattern: '^(\\S((.|\n)*\\S)?)?$',
      minLength: 5,
      maxLength: 128
    },
    client_id: {type: 'string', pattern: '^[a-z0-9]+-[a-z]+$'},
    api_roles: {
      type: 'array',
      minItems: 1,
      maxItems: 20,
      items: {type: 'string', pattern: '^[-a-z]+$', maxLength: 25}
    },
    grant_types: {
      type: 'array',
      uniqueItems: true,
      maxItems: 4,
      items: {enum: ['password_ecomm', 'scan_code', 'scan_code_password', 'scan_code_last_name', 'auth0_ecomm']}
    }
  },
  required: ['name', 'client_id', 'api_roles']
}
