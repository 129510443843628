import GETCache from '@/classes/GETCache'

export const appendSeller = data => {
  const sellerId = window.theApp?.seller?.id
  if (sellerId) {
    data.query = {seller: sellerId}
  }
  return data
}

export const archive = async (props, emit, arg, ui) => {
  const ops = ui ? {ui} : null
  const remover = props.remover ?? (id => APIService.delete(`${props.resourceName}/${id}`, null, ops))
  await remover(arg.id)
  GETCache.bust(props.resourceName)
  emit('archived', arg)
}

export const getEditRouteOptions = (props, record) =>
  appendSeller({
    name: props.editRoute,
    params: {id: record.id}
  })

export const edit = (props, router, record) => router.push(getEditRouteOptions(props, record))

export const getItemClass = (props, item) => {
  if (typeof props.itemCls === 'string') {
    return props.itemCls
  }
  if (typeof props.itemCls === 'function') {
    return props.itemCls(item)
  }
  return null
}

export const propDef = {
  data: {
    type: Object,
    required: true
  },
  editRoute: {
    type: String,
    required: true
  },
  // Promise that fetches the resources
  resourceName: {
    type: String,
    required: true
  },
  // Either String or Function which gets passed the item object and returns String
  itemCls: {},
  // Promise that archives/deletes resource by ID
  remover: {
    type: Function
  }
}
