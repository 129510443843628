<script setup>
import {computed} from 'vue'
import KebabMenu from '../KebabMenu.vue'
import ClickAgainToConfirmButton from '../ClickAgainToConfirmButton.vue'
import {weekdays, dateRangeLabel, timeRangeLabel} from '@/helpers/TimeRangeLabels'
import {HRApiDuration} from '@/helpers/format'
const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  tiers: {
    type: Array,
    required: true
  }
})
const emit = defineEmits(['edit', 'delete'])
const display = computed(() => {
  const fp = props.data.tier_pickers[0] // "fp" for "first picker"
  const offset = Math.abs(fp.offset)
  const action = fp.adjustor === 'absolute' ? `Change price tier to: ${props.tiers[offset]}` : `Move ${fp.offset > 0 ? 'up' : 'down'} ${offset} tier${offset > 1 ? 's' : ''}`
  let weekDayAndTimePart = ''
  if (props.data.day_of_week) {
    weekDayAndTimePart += `<span>${weekdays(props.data.day_of_week)}</span>`
  }
  if (props.data.time_of_day_start !== props.data.time_of_day_end) {
    weekDayAndTimePart += `<span>${timeRangeLabel(props.data.time_of_day_start, props.data.time_of_day_end)}</span>`
  }
  const timeRanges = []
  props.data.date_ranges.forEach(dr => {
    timeRanges.push(`<span>${dateRangeLabel(dr.start, dr.end)}</span>${weekDayAndTimePart}`)
  })
  const info = {
    heading: `${props.data.name} — ${action}`,
    timeRanges
  }
  if (fp.condition) {
    fp.condition.args.forEach(c => {
      switch (c.args[0].ref) {
        case 'Start':
          info.clock = `<span>Time to event is ${c.fn_bool === 'LT' ? 'less' : 'more'} than ${HRApiDuration(c.args[1].from_now, true)}</span>`
          break
        default:
          const isPercent = c.args[0].ref.includes('Percent')
          let str = `<span>${c.fn_bool === 'LT' ? 'Less' : 'More'} than ${parseInt(c.args[1].int, 10)}`
          if (isPercent) {
            str += '% of'
          }
          str += ' '
          const ticketsInKey = c.args[0].ref.includes('Tickets')
          switch (true) {
            case isPercent && ticketsInKey:
              str += 'capacity remaining'
              break
            case !isPercent && ticketsInKey:
              str += 'tickets remaining'
              break
            default:
              str += 'tickets sold'
              break
          }
          str += '</span>'
          info.tickets = str
          break
      }
    })
  }
  return info
})
</script>
<template>
  <div class="Schedule__Item">
    <div class="DD_Handler"></div>
    <div class="Schedule__Item__Content">
      <h4 v-shtml="display.heading" />
      <div v-for="c in display.timeRanges" class="timeRange Schedule__Item__Condition" v-shtml="c" />
      <div v-if="display.clock" v-shtml="display.clock" class="clock Schedule__Item__Condition" />
      <div v-if="display.tickets" v-shtml="display.tickets" class="tickets Schedule__Item__Condition" />
    </div>
    <KebabMenu>
      <button @click="emit('edit')" type="button">Edit</button>
      <ClickAgainToConfirmButton idleText="Delete" @action="emit('delete')" />
    </KebabMenu>
  </div>
</template>
<style lang="scss">
.Schedule__Item {
  &:not(:first-child) {
    margin-top: 16px;
  }
  background-color: white;
  display: flex;
  justify-content: space-between;
  &__Content {
    padding: 16px;
    flex-grow: 1;
  }
  h4 {
    margin: 0 0 8px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $v3-dark-color;
  }
  &:last-of-type {
    margin-bottom: 16px;
  }
  border-radius: 2px;

  .KebabMenu {
    margin: 8px 4px 0 0;
  }

  &__Condition {
    display: flex;
    align-items: stretch;
    margin-top: 7px;

    &::before {
      content: '';
      background-color: $ticket-group-body-colour;
      margin: 0 12px 0 0;
      width: 24px;
      height: 24px;
      display: inline-block;
      @extend %centered-bg;
    }
    span {
      display: flex;
      align-items: center;
      &:not(:first-child) {
        padding-left: 12px;
      }
      &:not(:last-child) {
        padding-right: 12px;
        border-right: 1px solid $separator-colour;
      }
    }
  }

  .timeRange {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_163_25115)'%3E%3Cpath d='M9.5 2H2.5C1.94772 2 1.5 2.44772 1.5 3V10C1.5 10.5523 1.94772 11 2.5 11H9.5C10.0523 11 10.5 10.5523 10.5 10V3C10.5 2.44772 10.0523 2 9.5 2Z' stroke='%23282B37' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 1V3' stroke='%23282B37' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4 1V3' stroke='%23282B37' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.5 5H10.5' stroke='%23282B37' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_163_25115'%3E%3Crect width='12' height='12' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
  }

  .clock::before {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_658_29385)'%3E%3Cpath d='M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z' stroke='%23282B37' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 3V6L8 7' stroke='%23282B37' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_658_29385'%3E%3Crect width='12' height='12' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  }

  .tickets::before {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.69399 5.96397L9.50118 6.77116' stroke='%23282B37' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.1159 8.3856L11.9231 9.1928' stroke='%23282B37' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M13.5377 10.8072L14.3449 11.6144' stroke='%23282B37' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.65875 9.99994L10.3091 4.3496C10.7549 3.9038 11.4777 3.9038 11.9235 4.3496L13.1343 5.56039C12.6885 6.00619 12.6885 6.72897 13.1343 7.17477C13.5801 7.62057 14.3028 7.62057 14.7486 7.17477L15.9594 8.38556C16.4052 8.83136 16.4052 9.55414 15.9594 9.99994L10.3091 15.6503C9.86329 16.0961 9.14051 16.0961 8.69471 15.6503L7.48392 14.4395C7.92972 13.9937 7.92972 13.2709 7.48392 12.8251C7.03812 12.3793 6.31534 12.3793 5.86954 12.8251L4.65875 11.6143C4.21295 11.1685 4.21295 10.4457 4.65875 9.99994' stroke='%23282B37' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}
</style>
