<script setup>
import {ref, computed} from 'vue'
import {useRouter} from 'vue-router'
import {appendSeller} from './Shared'
import GETCache from '@/classes/GETCache'
import {arrayRemove} from '@/helpers/format'
import HeaderWithButton from '../HeaderWithButton.vue'
import Item from './Item.vue'
import Sorter from '@old/Sorter'
const router = useRouter()

const props = defineProps({
  readOnly: {
    type: Boolean
  },
  heading: {
    type: String,
    required: true
  },
  createButtonText: {
    type: String,
    required: true
  },
  createRoute: {
    type: String,
    required: true
  },
  itemCmp: {
    type: Object,
    default() {
      return Item
    }
  },
  editRoute: {
    type: String,
    required: true
  },
  emptyMessage: {
    type: String,
    required: true
  },
  searchText: {
    type: String
  },
  // Promise that fetches the resources
  resourceName: {
    type: String,
    required: true
  },
  // Either String or Function which gets passed the item object and returns String
  itemCls: {},
  // Promise that archives/deletes resource by ID
  remover: {
    type: Function
  },
  buttonAllowedRoles: {
    type: Array
  },
  sorter: {
    type: Sorter
  }
})

const button = {
  text: props.createButtonText,
  action() {
    router.push(appendSeller({name: props.createRoute}))
  }
}
const list = ref([])
const data = await GETCache.get(props.resourceName)
if (props.sorter) {
  props.sorter.sort(data)
}
list.value = data
const query = ref('')
const showList = computed(() => (query.value ? list.value.filter(pos => pos.name.toLowerCase().includes(query.value.toLowerCase())) : list.value))
const onArchived = item => arrayRemove(list.value, item)
</script>
<template>
  <div class="V3ResourceList" :class="{'form-disabled': readOnly}">
    <HeaderWithButton :heading="heading" :button="button" :buttonAllowedRoles="buttonAllowedRoles" />
    <div v-if="searchText" class="SearchHolder">
      <div class="SearchInput">
        <input type="search" v-model="query" :placeholder="searchText" />
      </div>
    </div>
    <div class="List" v-if="list.length">
      <slot name="listHeader" />
      <component :is="itemCmp" v-for="item in showList" :key="item.id" :data="item" v-bind="props" @archived="onArchived" />
    </div>
    <div class="Empty" v-else>
      <div class="InfoBlock" v-html="emptyMessage" />
    </div>
  </div>
</template>
<style lang="scss">
.V3ResourceList {
  .FormSection,
  .Settings__Content__heading {
    margin: 0;
  }

  .SearchHolder {
    padding: 16px;
    > .SearchInput {
      width: 50%;
      input:focus {
        border-color: revert;
      }
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 8px 0 16px 0;
    padding: 0;
    color: $v3-dark-color;
  }

  .InfoBlock {
    border: 1px solid #ddd;
  }

  .Empty,
  .List {
    background-color: $ticket-group-body-colour;
  }

  .Empty {
    padding: 16px;
    .InfoBlock {
      margin: 0;
      border: 0;
    }
  }

  .ListItem0 {
    display: flex;
    align-items: center;
    margin: 8px;
    font-size: 16px;
    font-weight: 600;
    color: $header-colour;
    background-color: white;
    border-radius: 2px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    > div {
      margin-right: 8px;
      &.V3ResourceList__Name {
        padding: 16px 8px 16px 16px;
        min-height: 56px;
        align-content: center;
        margin-right: 0;
        cursor: pointer;
        flex-grow: 1;
      }
    }
  }

  .List {
    padding: 8px;

    &::before {
      content: 'Name';
      font-weight: 600;
      font-size: 12px;
      color: $v3-note-color;
      text-transform: uppercase;
      margin: 10px 0 16px 24px;
      display: block;
    }
  }
}
</style>
