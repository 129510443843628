<script>
import moment from 'moment-timezone'
import {computed, reactive, ref} from 'vue'
import {addCfg, addWatch} from '@/helpers/vModelPassThrough'
import field from '@/components/Field/HeldProperty.vue'
import dp from '@/components/DatePicker.vue'
import Modal from '@/components/Modal.vue'
import schema from '@/schema/apiappkey'
import Validator from '@old/schema/Validator'
import DaC from '@/components/DisplayAndCopy.vue'
import {dateFormat, dClone} from '@/helpers/format'
import {getContextTimezone, nowInContextTimezone, momentToISOString} from '@/helpers/datetime'
import {setError} from '@/helpers/ErrorHolder'

const validator = Validator.get(
  schema,
  '/tix/apiappkey',
  // Error setter
  ed => {
    // "ed" for error details
    if (ed.key) {
      if (ed.message.includes('required field')) {
        ed.message = 'Please enter a value.'
      }
      setError(ed.holder, ed.key, ed.message)
    }
  }
)

export default addCfg({
  props: {
    appId: {
      type: String,
      required: true
    },
    onCreated: {
      type: Function,
      required: true
    },
    keydata: {
      type: Object
    }
  },
  components: {
    dp,
    Modal,
    DaC,
    field
  },
  setup(props, {emit}) {
    const show = addWatch(props, emit)
    const onCancel = () => {
      show.value = false
    }
    const modalTitle = computed(() => `${props.keydata ? 'Edit' : 'Add an'} API Key`)
    const data = reactive(props.keydata || {name: ''})
    let dataToBeSubmitted
    const showKey = ref(false)
    const TZ = getContextTimezone()
    const onBeforeSubmit = () => {
      dataToBeSubmitted = dClone(data)
      if (dataToBeSubmitted.expires_at) {
        dataToBeSubmitted.expires_at = momentToISOString(moment.tz(dataToBeSubmitted.expires_at, TZ).endOf('day'))
      }
      return validator(dataToBeSubmitted, data)
    }
    const submitPromiseMaker = async () => {
      const created = await APIService.post(`application/${props.appId}/api_key`, dataToBeSubmitted)
      showKey.value = created.key
      created.key_masked = created.key.substring(0, 26) + '...' + created.key.substring(created.key.length - 4)
      delete created.key
      props.onCreated(created)
    }
    const today = nowInContextTimezone()
    const startDate = today.format(dateFormat)
    const dpOps = {
      container: '#APIKeyModal'
    }
    const cancelText = computed(() => (showKey.value ? 'Close' : 'Cancel'))
    return {
      data,
      show,
      modalTitle,
      submitPromiseMaker,
      dpOps,
      showKey,
      cancelText,
      startDate,
      onBeforeSubmit
    }
  }
})
</script>
<template>
  <Modal id="APIKeyModal" v-model="show" submitText="Confirm" :cancelText="cancelText" :submitPromiseMaker="submitPromiseMaker" :closeOnSubmit="false" :class="{ShowingKey: !!showKey}" :onBeforeSubmit="onBeforeSubmit">
    <template #header>{{ modalTitle }}</template>
    <div class="APIKeyModal">
      <template v-if="showKey">
        <div>
          Here is your API key for <strong>{{ data.name }}</strong
          >:
        </div>
        <div class="APIKey__Key">
          <label>API Key</label>
          <DaC :value="showKey" />
        </div>
        <div class="WarningBlock">For security reasons, you cannot view the key after closing this window. Make sure you copy it now.</div>
      </template>
      <template v-else>
        <div class="APIKeyModal__Name">
          <label class="required">Key name</label>
          <field :holder="data" name="name" />
        </div>
        <div class="APIKeyModal__Expiry">
          <label>Expiry date (optional)</label>
          <dp v-model="data.expires_at" :options="dpOps" :startDate="startDate" />
        </div>
      </template>
    </div>
  </Modal>
</template>
<style lang="scss">
.APIKeyModal {
  &__Expiry {
    margin-top: 16px;
    width: 50%;
  }
  .DisplayAndCopy {
    background-color: $white;
    margin-top: 8px;
    padding: 12px 10px 12px 12px;
    > div {
      word-break: break-all;
      font-family: monospace, monospace;
      color: $v3-note-color;
    }
  }
  .APIKey__Key {
    margin: 16px 0;
  }
  .WarningBlock {
    margin: 0;
  }
}
#APIKeyModal {
  &.ShowingKey {
    .Dialog__Submit {
      display: none;
    }
  }
}
</style>
