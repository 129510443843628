<script setup>
import {computed, onMounted, ref} from 'vue'
import {isoToMomentInContextTimezone} from '@/helpers/datetime'
import ClickAgainToConfirmButton from '@/components/ClickAgainToConfirmButton.vue'
import KebabMenu from '@/components/KebabMenu.vue'
import {techDateTimeFormat} from '@/helpers/format'
import {setTooltip} from '@/helpers/ui'
const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})
const emit = defineEmits(['archived'])
const el = ref(null)
const formatDate = k => (props.data[k] ? isoToMomentInContextTimezone(props.data[k]).format('MMM D, YYYY') : '–')
const disableOrEnableLabel = computed(() => (props.data.disabled ? 'Enable' : 'Disable'))
const disableOrEnable = async () => {
  const result = await APIService.patch(
    `application/${props.data.application_id}/api_key/${props.data.id}`,
    {
      disabled: !props.data.disabled
    },
    {
      ui: el.value
    }
  )
  props.data.disabled = result.disabled
}
const archive = async () => {
  await APIService.delete(`application/${props.data.application_id}/api_key/${props.data.id}`, null, {ui: el.value})
  emit('archived')
}
onMounted(() => {
  ;['created_at', 'expires_at', 'last_used'].forEach(dk => {
    if (props.data[dk]) {
      setTooltip(el.value.querySelector(`.dt-${dk}`), {
        title: `<strong>Time stamp:</strong> ${isoToMomentInContextTimezone(props.data[dk]).format(techDateTimeFormat)}`,
        html: true,
        container: 'body'
      })
    }
  })
})
</script>
<template>
  <div class="APIKey" :class="{isDisabled: data.disabled}" ref="el" data-submit-ui-disabled="1" data-submit-spinner="1">
    <div>
      <h4 v-html="data.name" />
      <div v-html="data.key_masked" />
    </div>
    <div class="dt-created_at" v-html="formatDate('created_at')" />
    <div class="dt-expires_at" v-html="formatDate('expires_at')" />
    <div class="dt-last_used" v-html="formatDate('last_used')" />
    <KebabMenu>
      <button @click="disableOrEnable" type="button" v-html="disableOrEnableLabel" />
      <ClickAgainToConfirmButton idleText="Archive" @action="archive" />
    </KebabMenu>
  </div>
</template>
<style lang="scss">
.APIKey {
  height: 64px;
  padding: 0 8px 0 16px;
  display: flex;
  align-items: center;
  border: 1px solid $v3-separator-color;
  border-radius: 2px;
  &::after {
    @extend %listBadge;
    content: 'ACTIVE';
    margin-right: 8px;
  }
  &.isDisabled::after {
    content: 'DISABLED';
    background-color: $ticket-group-body-colour;
    color: $percent-color;
  }
  h4 {
    font-weight: 600;
    margin-bottom: 8px !important;
  }
  > div {
    &:first-child {
      width: 278px;
      min-width: 278px;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: 132px;
      min-width: 132px;
    }
  }
  .KebabMenu {
    margin-left: auto;
    order: 7;
  }
}
</style>
