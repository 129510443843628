<script setup>
import ResourceList from '../ResourceList/List.vue'
import isReadOnly from './IsReadOnly'
import isEE from './IsExternalEnabled'
const readOnly = isReadOnly()
const itemCls = item => (item.config.type === 'external_default' ? 'PL-External' : null)
const isExternalEnabled = isEE()
await vue1AppSellerPromise
</script>
<template>
  <ResourceList heading="Price Libraries" createButtonText="Create Price Library" createRoute="create-pricelib" editRoute="edit-pricelib" resourceName="price_library" emptyMessage="You currently have no price libraries created. Click the ‘Create Price Library’ button to add a price library." :readOnly="readOnly" :itemCls="itemCls" class="PL-List" :class="{ExternalEnabled: isExternalEnabled}" />
</template>
<style lang="scss">
.PL-List.ExternalEnabled .List > div {
  .V3ResourceList__Name {
    &::after {
      @extend %listBadge;
      content: 'INTERNAL';
    }
    display: flex;
    align-items: center;
    > span {
      width: 60%;
      max-width: 60%;
      padding-right: 16px;
    }
  }
  &.PL-External .V3ResourceList__Name::after {
    content: 'EXTERNAL';
    background-color: $ticket-group-body-colour;
    color: $percent-color;
  }
}
</style>
