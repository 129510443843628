<script setup>
const props = defineProps({
  heading: {
    type: String,
    required: true
  },
  button: {
    type: Object
  },
  buttonAllowedRoles: {
    type: Array
  }
})
const isButtonAllowed = !props.buttonAllowedRoles || window.theApp.roleTeller.has(props.buttonAllowedRoles)
</script>
<template>
  <div class="Settings__Content__heading">
    <h2 v-shtml="heading" />
    <button v-if="button && isButtonAllowed" class="btn btn-primary uppercase" v-shtml="button.text" @click="button.action" />
  </div>
</template>
