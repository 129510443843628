<script>
import {computed, ref, watch, shallowReactive, nextTick} from 'vue'
import {isInteger} from '@/helpers/comparison'
import field from '@/components/Field/Field.vue'
import DDL from '@/components/DDL.vue'
import Duration from '@/components/Duration.vue'

const condOptionsAll = [
  {
    code: 'Start',
    label: 'the time to event'
  },
  {
    code: 'TicketsAvail',
    label: 'capacity remaining'
  },
  {
    code: 'CapacityUsed',
    label: 'tickets sold'
  }
]

const ticketsAvailOptions = [
  {
    code: 'TicketsAvailPercent',
    label: 'percent (%)'
  },
  {
    code: 'TicketsAvailQty',
    label: 'tickets'
  }
]

const ticketsSoldOptions = [
  {
    code: 'CapacityUsedPercent',
    label: 'percent (%)'
  },
  {
    code: 'CapacityUsedQty',
    label: 'tickets'
  }
]

const moreOrLessOptions = [
  {
    code: 'LT',
    label: 'less than'
  },
  {
    code: 'GT',
    label: 'more than'
  }
]

const isPositiveInt = (v, addCheck) => {
  if (!isInteger(v)) {
    return false
  }
  v = parseInt(v, 10)
  if (addCheck && !addCheck(v)) {
    return false
  }
  return v > 0
}

const TicketsAvailValidators = {
  TicketsAvailQty: isPositiveInt,
  TicketsAvailPercent: v => isPositiveInt(v, v => v < 100)
}
TicketsAvailValidators.CapacityUsedPercent = TicketsAvailValidators.TicketsAvailPercent
TicketsAvailValidators.CapacityUsedQty = TicketsAvailValidators.TicketsAvailQty

export default {
  props: {
    theOnlyOne: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  emits: ['delete', 'typechange'],
  setup(props, {emit}) {
    const cond = ref(props.data.args[0].ref === 'Start' ? 'Start' : props.data.args[0].ref.includes('Tickets') ? 'TicketsAvail' : 'CapacityUsed')
    watch(cond, c => emit('typechange', c))
    const onDelete = () => emit('delete')
    // The two possible condition options are to be present only when there is one single instance of this component.
    // When two of these components exist, only one option must remain in the DDL:
    const condOptions = computed(() => (props.theOnlyOne ? condOptionsAll : condOptionsAll.filter(c => c.code === cond.value)))
    const ticketAvailOnBlurValidator = v => TicketsAvailValidators[props.data.args[0].ref](v)
    return {
      cond,
      condOptions,
      moreOrLessOptions,
      ticketsAvailOptions,
      ticketsSoldOptions,
      onDelete,
      ticketAvailOnBlurValidator
    }
  },
  components: {
    DDL,
    Duration,
    field
  }
}
</script>
<template>
  <div class="FormBlock ScheduleRuleCondition">
    <div>
      <DDL v-model="cond" :options="condOptions" class="cond" />
      <span>is</span>
      <DDL v-model="data.fn_bool" :options="moreOrLessOptions" class="moreOrLess" />
      <div class="MR__Delete" @click="onDelete"></div>
    </div>
    <Duration v-if="cond === 'Start'" v-model="data.args[1].from_now" />
    <div v-if="cond !== 'Start' && data.args[1].int !== undefined" class="TicketsAvail">
      <field v-model="data.args[1].int" type="number" :onBlurValidator="ticketAvailOnBlurValidator" />
      <DDL v-model="data.args[0].ref" :options="cond === 'TicketsAvail' ? ticketsAvailOptions : ticketsSoldOptions" />
    </div>
  </div>
</template>
<style lang="scss">
.ScheduleRuleCondition {
  @extend %AddedAND;
  position: relative;
  > div {
    @extend %pairSpan;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:last-child {
      margin-top: 16px;
      > span {
        padding-left: 8px;
      }
    }
  }
  .cond {
    flex-grow: 1;
  }
  .moreOrLess {
    width: 144px;
    min-width: 144px;
  }
  .TicketsAvail {
    justify-content: flex-start;
    input {
      width: 72px !important;
      margin-right: 16px;
    }
  }
}
</style>
