<script setup>
import {computed, ref} from 'vue'
import {useRouter} from 'vue-router'
import AppWriteAllowedRoles from './AppWriteAllowedRoles'
import {techDateTimeFormat, arrayRemove} from '@/helpers/format'
import {isoToMomentInContextTimezone} from '@/helpers/datetime'
import ExpandableBlock from '../ExpandableBlock.vue'
import Key from './Key.vue'
import KeyModal from './KeyModal.vue'
import modalSingleton from '@/classes/ModalSingleton'
import ClickAgainToConfirmButton from '../ClickAgainToConfirmButton.vue'
import {archive as _archive, propDef} from '../ResourceList/Shared'
const router = useRouter()
const props = defineProps(propDef)
const emit = defineEmits(['archive'])
const archive = btn => _archive(props, emit, props.data, btn)
const lastUsed = computed(() => (props.data.last_login_at ? isoToMomentInContextTimezone(props.data.last_login_at).format(techDateTimeFormat) : '–'))
const keys = ref(props.data.keys || [])
let newKey
const addKey = () => {
  modalSingleton.showWith({
    modalCmp: KeyModal,
    props: {
      appId: props.data.id,
      onCreated: k => {
        newKey = k
        keys.value.push(k)
      },
      toastOnSubmitted: () => `API Key ${newKey.name} has been successfully created as an API Key`
    }
  })
}
const isAppWriteAllowed = window.theApp.roleTeller.has(AppWriteAllowedRoles)
const onKeyArchived = key => arrayRemove(keys.value, key)
</script>
<template>
  <ExpandableBlock class="APIAppItem">
    <template #header>
      <div>
        <h3>{{ data.name }}</h3>
        <span>API Keys: {{ data.keys.length }}</span>
      </div>
      <span class="LastUsed" v-html="lastUsed" />
    </template>
    <template #content>
      <div class="APIAppItem__Body">
        <div v-if="data.keys.length">
          <Key v-for="key in data.keys" :key="key.id" :data="key" @archived="onKeyArchived(key)" />
        </div>
        <div v-else class="InfoBlock">There are no added API keys.</div>
        <div class="APIAppItem__Add"><div @click="addKey">Add an API key</div></div>
      </div>
      <div class="APIAppItem__Buttons" v-if="isAppWriteAllowed">
        <ClickAgainToConfirmButton class="btn btn-black uppercase" @action="archive" idleText="Archive" />
      </div>
    </template>
  </ExpandableBlock>
</template>
<style lang="scss">
.APIAppItem {
  border-radius: 2px;
  overflow: hidden;
  h4 {
    margin-bottom: 4px;
  }
  .ExpandableBlock__Content {
    border-top: 1px solid $v3-separator-color;
    background-color: $white;
  }
  .InfoBlock {
    margin: 0;
  }
  &__Body {
    padding: 16px;
  }
  &__Buttons {
    text-align: right;
    border-top: 1px solid $v3-separator-color;
    padding: 16px;
  }
  &__Add {
    margin-top: 16px;
    > div {
      @extend %addLink;
    }
  }
  .ExpandableBlock__Header {
    height: 64px;
    &__Main {
      position: relative;
      h3 {
        margin: 0 0 8px 0;
      }
    }
  }
  .LastUsed {
    position: absolute;
    width: 140px;
    left: 575px;
    white-space: nowrap;
  }
  .APIKey {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
</style>
