import {createRouter, createWebHistory} from 'vue-router'
import View from '../routeComponents/View.vue'
import MessageRule from '../routeComponents/MessageRule.vue'
import StripeAccounts from '../components/StripeAccounts/View.vue'
import APIApps from '../components/APIApps/View.vue'
import APIApp from '../components/APIApps/Form.vue'
import SellerConfiguration from '../routeComponents/SellerConfiguration.vue'
import Pos from '../components/Pos/View.vue'
import PosForm from '../components/Pos/Form.vue'
import PriceLib from '../components/PriceLib/View.vue'
import PriceLibForm from '../components/PriceLib/Form.vue'
import StaffGroups from '../components/Staff/StaffGroups.vue'
import StaffManagement from '../components/Staff/StaffManagement.vue'
import PortalSettingsRoute from '../routeComponents/PortalSettingsRoute.vue'
import AutomatedTask from '../routeComponents/AutomatedTask.vue'
import EventDetails from '../routeComponents/EventDetails.vue'
import StaffMembers from '@/components/Staff/StaffMembers.vue'
import StaffMemberForm from '@/components/Staff/StaffMemberForm.vue'
import AddStaffGroup from '@/components/Staff/StaffGroupForm.vue'
import {LoginCallback} from '@okta/okta-vue'
import EntraLoginCallback from '@/routeComponents/EntraLoginCallback.vue'
import ConfigDetailRoute from '@/config/ConfigDetailRoute.vue'
import ConfigListRoute from '@/config/ConfigListRoute.vue'

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/okta/callback',
      name: 'okta-callback',
      component: LoginCallback
    },
    {
      path: '/entra/callback',
      name: 'entra-callback',
      component: EntraLoginCallback
    },
    {
      path: '/checkout-rules',
      component: View,
      children: [
        {
          path: 'message-rules',
          component: View,
          children: [
            {
              path: 'create',
              name: 'add-message-rule',
              component: MessageRule
            },
            {
              path: ':id',
              name: 'edit-message-rule',
              props: true,
              component: MessageRule
            }
          ]
        }
      ]
    },
    {
      path: '/portal',
      name: 'portal-configuration',
      component: PortalSettingsRoute,
      children: [
        {
          path: 'stripe',
          name: 'stripe',
          component: StripeAccounts
        },
        {
          path: 'staff-management',
          name: 'staff-management',
          component: StaffManagement,
          children: [
            {
              path: '',
              name: 'staff-members',
              component: StaffMembers
            },
            {
              path: 'add',
              name: 'add-staff-member',
              component: StaffMemberForm
            },
            {
              path: 'edit/:id',
              name: 'edit-staff-member',
              props: true,
              component: StaffMemberForm
            },
            {
              path: 'groups',
              name: 'staff-groups',
              component: StaffGroups
            },
            {
              path: 'groups/add',
              name: 'add-staff-group',
              component: AddStaffGroup
            },
            {
              path: 'groups/edit/:id',
              props: true,
              name: 'edit-staff-group',
              component: AddStaffGroup
            }
          ]
        },
        {
          path: 'webhooks',
          component: View
        },
        {
          path: 'webhooks/add',
          name: 'webhooks-add',
          component: AutomatedTask
        },
        {
          path: 'webhooks/:id',
          name: 'webhooks-edit',
          props: true,
          component: AutomatedTask
        },
        {
          path: 'apiapp',
          name: 'apiapp',
          component: APIApps
        },
        {
          path: 'apiapp/add',
          name: 'apiapp-add',
          component: APIApp
        },
        {
          path: 'apiapp/:id',
          name: 'apiapp-edit',
          props: true,
          component: APIApp
        },
        {
          path: 'config',
          name: 'portal-config',
          component: ConfigListRoute,
          props: {
            resource: 'portal'
          }
        },
        {
          path: 'config/:namespace/:name',
          name: 'portal-config-item',
          component: ConfigDetailRoute,
          props: route => ({
            resource: 'portal',
            namespace: route.params.namespace,
            name: route.params.name
          })
        }
      ]
    },
    {
      path: '/configuration',
      component: SellerConfiguration,
      children: [
        {
          path: 'pos',
          name: 'pos',
          children: [
            {
              path: '',
              name: 'pos',
              component: Pos
            },
            {
              path: ':id',
              props: true,
              name: 'edit-pos',
              component: PosForm
            },
            {
              path: 'create',
              name: 'create-pos',
              component: PosForm
            }
          ]
        },
        {
          path: 'pricelib',
          name: 'pricelib',
          children: [
            {
              path: '',
              name: 'pricelib',
              component: PriceLib
            },
            {
              path: ':id',
              props: true,
              name: 'edit-pricelib',
              component: PriceLibForm
            },
            {
              path: 'create',
              name: 'create-pricelib',
              component: PriceLibForm
            }
          ]
        },
        {
          path: 'config',
          name: 'seller-config',
          component: ConfigListRoute,
          props: route => ({
            resource: 'seller',
            resource_id: route.query.seller
          })
        },
        {
          path: 'config/:namespace/:name',
          name: 'seller-config-item',
          component: ConfigDetailRoute,
          props: route => ({
            resource: 'seller',
            resource_id: route.query.seller,
            namespace: route.params.namespace,
            name: route.params.name
          })
        }
      ]
    },
    {
      path: '/event/:id',
      component: EventDetails,
      children: [
        {
          path: 'config',
          name: 'event-config',
          component: ConfigListRoute,
          props: route => ({
            resource: 'event_template',
            resource_id: route.params.id
          })
        },
        {
          path: 'config/:namespace/:name',
          name: 'event-config-item',
          component: ConfigDetailRoute,
          props: route => ({
            resource: 'event_template',
            resource_id: route.params.id,
            namespace: route.params.namespace,
            name: route.params.name
          })
        }
      ]
    },
    {
      name: 'ticket-group-config',
      path: '/event/:id/ticket-group/:group_id/config',
      component: ConfigListRoute,
      props: route => ({
        resource: 'ticket_group',
        resource_id: route.params.group_id
      })
    },
    {
      name: 'ticket-group-config-item',
      path: '/event/:id/ticket-group/:group_id/config/:namespace/:name',
      component: ConfigDetailRoute,
      props: route => ({
        resource: 'ticket_group',
        resource_id: route.params.group_id,
        namespace: route.params.namespace,
        name: route.params.name
      })
    }
  ]
})
