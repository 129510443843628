export default {
  $id: '/tix/price_library/internal',
  type: 'object',
  $defs: {
    create: {
      type: 'object',
      additionalProperties: false,
      properties: {
        name: {
          $ref: '/tix/core#/$defs/string_1_128'
        },
        description: {
          $ref: '/tix/core#/$defs/string_0_1024'
        },
        config: {
          type: 'object',
          additionalProperties: false,
          minProperties: 1,
          properties: {
            tiers: {
              $ref: '#/$defs/tiers'
            },
            rows: {
              type: 'array',
              items: {
                $ref: '#/$defs/row_create'
              },
              uniqueItems: true,
              minItems: 1,
              maxItems: 25
            },
            schedules: {
              $ref: '#/$defs/schedules'
            },
            phases: {
              $ref: '#/$defs/phases'
            },
            global_post_modifier: {
              $ref: '#/$defs/node_nullable'
            }
          },
          required: ['tiers', 'rows', 'schedules']
        }
      },
      required: ['name', 'config']
    },
    matrix_for_pending_lib: {
      type: 'object',
      additionalProperties: false,
      minProperties: 1,
      properties: {
        tiers: {
          $ref: '#/$defs/tiers'
        },
        rows: {
          type: 'array',
          items: {
            $ref: '#/$defs/row_create'
          },
          uniqueItems: true,
          minItems: 1,
          maxItems: 25
        },
        phases: {
          $ref: '#/$defs/phases'
        },
        schedules: {
          comment: 'schedules are ignored by the matrix. The matrix calculates all prices regardless of schedules. Included for convenience',
          type: 'array',
          items: {
            type: 'object',
            additionalProperties: true
          }
        }
      },
      required: ['tiers', 'rows']
    },
    calc_debug: {
      type: 'object',
      additionalProperties: false,
      properties: {
        event_session_id: {
          $ref: '/tix/core#/$defs/guid'
        },
        ticket_type_id: {
          $ref: '/tix/core#/$defs/guid'
        }
      },
      required: ['ticket_type_id']
    },
    modify: {
      type: 'object',
      additionalProperties: false,
      minProperties: 1,
      properties: {
        name: {
          $ref: '/tix/core#/$defs/string_1_128'
        },
        description: {
          $ref: '/tix/core#/$defs/string_0_1024'
        },
        config: {
          type: 'object',
          additionalProperties: false,
          minProperties: 1,
          properties: {
            tiers: {
              $ref: '#/$defs/tiers'
            },
            rows: {
              type: 'array',
              items: {
                $ref: '#/$defs/row_update'
              },
              uniqueItems: true,
              minItems: 1,
              maxItems: 25
            },
            schedules: {
              $ref: '#/$defs/schedules'
            },
            phases: {
              $ref: '#/$defs/phases'
            },
            global_post_modifier: {
              $ref: '#/$defs/node_nullable'
            }
          }
        }
      }
    },
    tiers: {
      type: 'array',
      items: {
        $ref: '/tix/core#/$defs/string_1_128'
      },
      uniqueItems: true,
      minItems: 1,
      maxItems: 25
    },
    schedules: {
      type: 'array',
      items: {
        $ref: '#/$defs/schedule'
      },
      uniqueItems: true,
      minItems: 1,
      maxItems: 25
    },
    phases: {
      type: ['array', 'null'],
      items: {
        $ref: '#/$defs/phase'
      },
      uniqueItems: true,
      minItems: 1,
      maxItems: 25
    },
    phase: {
      type: 'object',
      additionalProperties: false,
      properties: {
        name: {
          $ref: '/tix/core#/$defs/string_1_128'
        },
        start_date: {
          type: 'string',
          format: 'date'
        }
      },
      required: ['name']
    },
    row_update: {
      type: 'object',
      additionalProperties: false,
      minProperties: 2,
      properties: {
        id: {
          $ref: '/tix/core#/$defs/guid'
        },
        name: {
          $ref: '/tix/core#/$defs/string_1_128'
        },
        phases: {
          type: 'array',
          items: {
            $ref: '#/$defs/row_phase'
          },
          uniqueItems: false,
          minItems: 1,
          maxItems: 25
        }
      },
      required: ['name', 'phases']
    },
    row_create: {
      type: 'object',
      additionalProperties: false,
      properties: {
        name: {
          $ref: '/tix/core#/$defs/string_1_128'
        },
        phases: {
          type: 'array',
          items: {
            $ref: '#/$defs/row_phase'
          },
          uniqueItems: false,
          minItems: 1,
          maxItems: 25
        }
      },
      required: ['name', 'phases']
    },
    row_phase: {
      type: 'object',
      additionalProperties: false,
      properties: {
        nominal: {
          $ref: '/tix/core#/$defs/decimal'
        },
        tier_modifiers: {
          type: 'array',
          items: {
            $ref: '#/$defs/node'
          },
          uniqueItems: false,
          minItems: 1,
          maxItems: 25
        }
      },
      required: ['nominal', 'tier_modifiers']
    },
    ref: {
      enum: ['Nominal', 'Computed', 'Start', 'TicketsAvailQty', 'TicketsAvailPercent', 'CapacityUsedQty', 'CapacityUsedPercent', 'PriceUnavailable']
    },
    node_nullable: {
      oneOf: [{$ref: '#/$defs/node'}, {type: 'null'}]
    },
    node: {
      type: 'object',
      additionalProperties: false,
      minProperties: 1,
      maxProperties: 3,
      properties: {
        ref: {
          $ref: '#/$defs/ref'
        },
        fn: {
          $ref: '#/$defs/fn_for_dec'
        },
        fn_date: {
          $ref: '#/$defs/fn_for_date'
        },
        fn_bool: {
          $ref: '#/$defs/fn_for_bool'
        },
        int: {
          type: 'integer'
        },
        decimal: {
          $ref: '/tix/core#/$defs/decimal'
        },
        date: {
          type: 'string',
          format: 'date'
        },
        datetime: {
          type: 'string',
          format: 'date-time'
        },
        time: {
          type: 'string',
          format: 'time'
        },
        from_now: {
          comment: "duration from now. Compare with dates (generally compare with the variable 'Start')",
          $ref: '/tix/core#/$defs/duration'
        },
        args: {
          type: 'array',
          items: {
            $ref: '#/$defs/node'
          },
          uniqueItems: false
        }
      },
      oneOf: [{required: ['ref']}, {required: ['fn', 'args']}, {required: ['fn_date', 'args']}, {required: ['fn_bool']}, {required: ['int']}, {required: ['decimal']}, {required: ['date']}, {required: ['datetime']}, {required: ['time']}, {required: ['from_now']}]
    },
    node_picker: {
      type: ['object', 'null'],
      additionalProperties: false,
      properties: {
        fn_bool: {
          $ref: '#/$defs/fn_for_bool'
        },
        args: {
          type: 'array',
          items: {
            $ref: '#/$defs/node'
          },
          uniqueItems: false
        }
      },
      required: ['fn_bool']
    },
    fn_for_bool: {
      enum: ['EQ', 'LT', 'GT', 'LTE', 'GTE', 'AND', 'OR']
    },
    fn_for_dec: {
      enum: ['ADD', 'SUB', 'MUL', 'DIV', 'ROUND', 'ROUND_UP', 'ROUND_DOWN', 'ROUND_BANK', 'ADD_PC', 'SUB_PC']
    },
    fn_for_date: {
      enum: ['DATEONLY', 'TIMEONLY']
    },
    schedule: {
      type: 'object',
      additionalProperties: false,
      minProperties: 2,
      properties: {
        name: {
          $ref: '/tix/core#/$defs/string_1_128'
        },
        day_of_week: {
          type: 'array',
          items: {
            type: 'integer'
          },
          uniqueItems: true,
          minItems: 1,
          maxItems: 7
        },
        date_ranges: {
          type: 'array',
          items: {
            $ref: '#/$defs/date_range'
          },
          uniqueItems: true,
          minItems: 1,
          maxItems: 25
        },
        time_of_day_start: {
          type: 'string',
          format: 'time'
        },
        time_of_day_end: {
          type: 'string',
          format: 'time'
        },
        tier_pickers: {
          type: 'array',
          items: {
            $ref: '#/$defs/tier_picker'
          },
          uniqueItems: true,
          minItems: 1,
          maxItems: 25
        },
        stop: {
          type: 'boolean'
        }
      },
      required: ['tier_pickers', 'stop', 'name']
    },
    date_range: {
      type: ['object'],
      additionalProperties: false,
      minProperties: 1,
      properties: {
        start: {
          type: 'string',
          format: 'date'
        },
        end: {
          type: 'string',
          format: 'date'
        }
      }
    },
    tier_picker: {
      type: ['object'],
      additionalProperties: false,
      minProperties: 1,
      properties: {
        condition: {
          $ref: '#/$defs/node_picker'
        },
        adjustor: {
          enum: ['relative', 'absolute']
        },
        offset: {
          type: 'integer'
        }
      },
      required: ['condition', 'adjustor', 'offset']
    }
  }
}
