export default {
  $id: '/tix/apiappkey',
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {type: 'string', pattern: '^(\\S((.|\n)*\\S)?)?$', minLength: 5, maxLength: 128},
    expires_at: {type: 'string', format: 'date-time'}
  },
  required: ['name']
}
