<script>
import {onMounted, ref, reactive} from 'vue'
import toast from '@/classes/ToastSingleton'
import {useRouter} from 'vue-router'
import field from '@/components/Field/HeldProperty.vue'
import schema from '@/schema/apiapp'
import GETCache from '@/classes/GETCache'
import Validator from '@old/schema/Validator'
import {dClone} from '@/helpers/format'
import {NotFoundError} from '@/api/error/Misc.js'
import {setError} from '@/helpers/ErrorHolder'

const loginMethods = schema.properties.grant_types.items.enum
const validator = Validator.get(
  schema,
  '/tix/apiapp',
  // Error setter
  ed => {
    // "ed" for error details
    if (ed.key) {
      if (ed.message.includes('required field')) {
        ed.message = 'Please enter a value.'
      } else if (ed.message.startsWith('Must match pattern')) {
        ed.message = 'Please enter a properly formatted value.'
      }
      setError(ed.holder, ed.key, ed.message)
    }
  }
)

export default {
  components: {
    field
  },
  async setup(props) {
    const router = useRouter()
    const el = ref(null)
    onMounted(() => el.value.querySelector('.NameField input').focus())
    const roles = await GETCache.get('apiapp_role')
    const data = reactive({
      client_id: `${PORTAL.slug}-`
    })
    const buttons = ref(null)
    const cancel = () => router.push({name: 'apiapp'})
    const save = async () => {
      const d = dClone(data)
      if (validator(d, data)) {
        await APIService.post('application', d, {ui: buttons.value})
        GETCache.bust('application')
        toast.success(`${data.name} has been successfully created as an API Application`)
        cancel()
      }
    }
    return {
      data,
      cancel,
      save,
      buttons,
      roles,
      loginMethods,
      el
    }
  }
}
</script>
<template>
  <div class="APIApp" ref="el">
    <div class="Settings__Content__heading">
      <h2>Create API Application</h2>
    </div>
    <form class="SettingForm">
      <div class="FieldWrapper NameField">
        <label class="required">Name</label>
        <field :holder="data" name="name" />
      </div>

      <div class="FieldWrapper FieldWrapper__FullWidth">
        <label class="required">Client ID</label>
        <field :holder="data" name="client_id" />
      </div>

      <div class="FieldWrappers">
        <div class="FieldWrapper">
          <label class="required">Roles</label>
          <field type="dropdown" :options="roles" :holder="data" :multiple="true" name="api_roles" placeholder="Select roles" />
        </div>
        <div class="FieldWrapper">
          <label>Login methods (optional)</label>
          <field type="dropdown" :options="loginMethods" :holder="data" name="grant_types" :multiple="true" placeholder="Select login methods" />
        </div>
      </div>

      <div class="Form__Actions" ref="buttons">
        <button class="btn btn-blue-cancel uppercase" type="button" @click.prevent="cancel">Cancel</button>
        <button class="btn btn-primary uppercase" type="submit" @click.prevent="save" data-submit-ui-disabled="1" data-submit-spinner="1">Save API Application</button>
      </div>
    </form>
  </div>
</template>
<style lang="scss">
.APIApp .SettingForm {
}
</style>
